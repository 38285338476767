<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog_"
            width="500"
        >
            <v-card>
                <v-card-title
                    class="text-h5 primary"
                >
                    <span class="white--text"> {{ title }} </span>
                </v-card-title>

                <v-card-text>
                    <v-list dense>
                        <!--    <v-subheader>Seleccione...</v-subheader>-->
                        <v-text-field
                            v-model="search"
                            :append-icon="!search ? 'mdi-magnify' : undefined"
                            clearable
                            :label="$t('common.search')"
                            single-line
                            hide-details
                        />
                        <!--                      v-model="selectedItem"-->
                        <v-list-item-group
                            color="primary"
                        >
                            <v-list-item
                                v-for="(item, i) in filteredItems"
                                :key="i"
                                @click="selectWord(item.attributes.name)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.attributes.name" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>

                <!-- <v-divider />

                               <v-card-actions>
                   <v-spacer />
                   <v-btn
                       color="primary"
                       text
                       @click="dialog_ = false"
                   >
                       I accept
                   </v-btn>
               </v-card-actions>-->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'PurposeWordSelectDialog',
    props: {
        title: { type: String, default: "" },
        dialog: { type: Boolean, default: false },
        items: { type: Array, default() { return [] } }
    },
    data() {
        return {
            // selectedItem: -1,
            search: ""

        }
    },
    computed: {
        dialog_: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.selectWord("");
            }
        },
        filteredItems() {
            if (!this.search) return this.items;
            return this.items.filter(e => e.attributes.name.toUpperCase().includes(this.search.toUpperCase()));
        }
    },
    methods: {
        selectWord(valueSelected) {
            /* const valueSelected = this.selectedItem >= 0
                ? this.items[this.selectedItem].text : "";

            this.selectedItem = -1; */

            this.search = "";

            this.$emit('closed:dialog', valueSelected);
        }
    }

}
</script>

<style scoped>

</style>
