<template>
    <v-card-title
        v-if="purpose"
        class="text-h6 ql-editor mb-0"
        style="min-height: 80px !important;"
    >
        <slot />

        <div
            v-if="purpose.attributes && purpose.attributes.body"
            style="width: 100%"
            v-html="purpose.attributes.body"
        />
        <div
            v-else-if="purpose.body"
            style="width: 100%"
            v-html="purpose.body"
        />
        <div
            v-else
            class="text-subtitle-2 text-gray-600"
            style="width: 100%"
            v-html="$t('purposes.notDefined')"
        />
    </v-card-title>
</template>

<script>
export default {
    name: 'PurposeText',
    props: {
        purpose: {
            type: Object,
            default() {
                return null;
            }
        }
    }
}
</script>

<style scoped>

</style>
